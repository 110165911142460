import { DateTime } from "luxon";
import React, { FunctionComponent, useEffect, useState } from "react";
import "./RenderAddress.css";

const sixtySeconds = 60000;

export interface IRenderAddress extends React.HTMLProps<HTMLElement> {
    name?: string | undefined;
    address1: string | null | undefined;
    address2: string | null | undefined;
    city: string | null | undefined;
    state: string | null | undefined;
    zip: string | null | undefined;
    county?: string | null | undefined;
    timezone?: string | null | undefined;
}

export const RenderAddress: FunctionComponent<IRenderAddress> = ({
    name,
    address1,
    address2,
    city,
    state,
    zip,
    county,
    timezone,
    ...props
}) => {
    const [currentTime, setCurrentTime] = useState<string>("");

    useEffect(() => {
        if (timezone) {
            const updateCurrentTime = () => {
                const date = DateTime.now().setZone(timezone);
                setCurrentTime(date.toFormat("hh:mm a"));
            };

            updateCurrentTime();
            const intervalId = setInterval(updateCurrentTime, sixtySeconds);

            return () => clearInterval(intervalId);
        }
    }, [timezone]);

    const renderLabelValue = (label: string, value: string) => (
        <div>
            <span
                style={{
                    marginRight: "4px",
                }}>
                {label}
            </span>
            <span>{value}</span>
        </div>
    );

    return (
        <address className={["displayAddressBlock", "fs-exclude"].join(" ")} {...props}>
            {name && <div>{name}</div>}
            {address1 && <div>{address1}</div>}
            {address2 && <div>{address2}</div>}
            <div>
                {city && <span>{`${city}, `}</span>}
                {state && <span>{`${state} `}</span>}
                {zip && <span>{zip}</span>}
            </div>
            {county && <div>County: {county}</div>}
            {timezone && (
                <div>
                    {renderLabelValue("Time Zone:", timezone)}
                    {renderLabelValue("Local Time:", currentTime)}
                </div>
            )}
        </address>
    );
};
